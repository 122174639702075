import React, { useEffect, useState, useRef } from 'react';
import { config } from '../util/config';

const useParty = (username, partyname, onError) => {
  const [party, setParty] = useState(undefined);

  useEffect(() => {
    if (username && partyname) {
      const source = new EventSource(config.API_URL + '/party?' + new URLSearchParams({
        user: username,
        party: partyname,
      }))

      source.onerror = evt => {
        onError();
        setParty(undefined)
      }
      source.onmessage = evt => {
        if (evt.data) {
          const party = JSON.parse(evt.data);
          setParty(party);
        }
      }
      return () => {
        source.close();
        setParty(undefined);
      }
    }
  }, [username, partyname])

  return party;
}

export default useParty;
