import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import NiceContainer from './NiceContainer';

import wikiLogo from '../../res/wikipedia2.svg';
import MiniIcon from './MiniIcon';

export const defaultSettings = {
  metaPages: false,
  infoboxes: true,
  thumbnails: true,
  seeAlso: true,
  navboxes: true,
  showOthersStatus: true,
  showOthersCurrent: true,
  showOthersPath: false,
  showOthersClicks: true,
  showClicksToBeat: false, // TODO: implement
  hideInfoUntilEnd: false,
  clicks: false,
  loopsResetPath: false, // TODO: implement
  loopsDisallow: false, // TODO: implement
 };
const nilSettings = Object.keys(defaultSettings).reduce((acc, key) => ({ ...acc, [key]: false }), {});

const RunSettings = ({ show, settings, setSettings, disallowEdit, ready, start }) => {
  const nodeRef = useRef();

  const s = settings ?? nilSettings;
  const set = settings ? setSettings : v => { }

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={3000}>
      <div className='settings' ref={nodeRef}>
        <div className='settings-align'>
          <div className='settings-counter-align'>
            <div className={'settings-content' + (disallowEdit ? ' no-edit' : '')} >
              <div>
                <NiceContainer label='Run mode'>
                  <SettingLine value={!s.clicks} setValue={v => set({ ...s, clicks: !v })} label='Race for time' />
                  <SettingLine value={s.clicks} setValue={v => set({ ...s, clicks: v })} label='Race for fewest clicks' />
                </NiceContainer>
                <NiceContainer label='Navigation'>
                  <SettingLine value={s.infoboxes} setValue={v => set({ ...s, infoboxes: v })} label='Show info boxes' />
                  <SettingLine value={s.thumbnails} setValue={v => set({ ...s, thumbnails: v })} label='Show thumbnail boxes' />
                  <SettingLine value={s.seeAlso} setValue={v => set({ ...s, seeAlso: v })} label="Show 'See also' sections" />
                  <SettingLine value={s.navboxes} setValue={v => set({ ...s, navboxes: v })} label="Show navigation boxes" />
                  <SettingLine value={s.metaPages} setValue={v => set({ ...s, metaPages: v })} label='Allow meta pages' />
                </NiceContainer>
              </div>
              <div>
                <NiceContainer label='Opponent information'>
                  <SettingLine value={!s.hideInfoUntilEnd && s.showOthersStatus} setValue={v => set({ ...s, showOthersStatus: v })} label='Show status of others' disabled={s.hideInfoUntilEnd} />
                  <SettingLine value={!s.hideInfoUntilEnd && s.showOthersCurrent} setValue={v => set({ ...s, showOthersCurrent: v })} label='Show current page of others' disabled={s.hideInfoUntilEnd} />
                  <SettingLine value={!s.hideInfoUntilEnd && s.showOthersPath} setValue={v => set({ ...s, showOthersPath: v })} label='Show path of others' disabled={s.hideInfoUntilEnd} />
                  <SettingLine value={!s.hideInfoUntilEnd && s.showOthersClicks} setValue={v => set({ ...s, showOthersClicks: v })} label='Show click counts of others' disabled={s.hideInfoUntilEnd || !s.clicks} />
                  <SettingLine value={!s.hideInfoUntilEnd && s.showClicksToBeat} setValue={v => set({ ...s, showClicksToBeat: v })} label='Show click count to beat' disabled={s.hideInfoUntilEnd || !s.clicks} />
                  <SettingLine value={s.hideInfoUntilEnd} setValue={v => set({ ...s, hideInfoUntilEnd: v })} label='Hide all info until round end' />
                </NiceContainer>
                <NiceContainer label='Loops'>
                  <SettingLine value={!s.loopsDisallow && s.loopsResetPath} setValue={v => set({ ...s, loopsResetPath: v })} label='Remove loops from paths' disabled={s.loopsDisallow} />
                  <SettingLine value={s.loopsDisallow} setValue={v => set({ ...s, loopsDisallow: v })} label='Disallow loops' />
                </NiceContainer>
              </div>
            </div>
            <button className='start-run' onClick={start} disabled={!ready}><MiniIcon svg={wikiLogo}/>Start run</button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

const SettingLine = ({ value, setValue, label, disabled }) => {
  return (
    <div className={'setting-line input-line' + (disabled ? ' disabled' : '')}>
      <label>{label}</label>
      <input
        disabled={disabled}
        type='checkbox'
        checked={value}
        onChange={evt => setValue(evt.target.checked)}
      />
    </div>
  )
}

export default RunSettings;
