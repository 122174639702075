
const prod = {
  API_URL: '/api/v1',
  WIKI_REST_URL: 'https://en.wikipedia.org/w/rest.php/v1',
  WIKI_API_URL: 'https://en.wikipedia.org/w/api.php'
}

const dev = {
  API_URL: 'http://localhost:8080/api/v1',
  WIKI_REST_URL: 'https://en.wikipedia.org/w/rest.php/v1',
  WIKI_API_URL: 'https://en.wikipedia.org/w/api.php'
};
  
export const config = process.env.NODE_ENV === 'development' ? dev: prod;
