import React, { useEffect, useReducer, useRef, useState } from 'react';

const MiniIcon = ({ svg, enabled }) => {
  return (
    <img
      className={`mini-icon ${enabled ? '' : 'hidden'}`}
      src={svg}
    />
  )
}

export default MiniIcon;

