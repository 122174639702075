import React, { useEffect, useState }  from 'react';
import { config } from '../util/config';

const useWiki = pageid => {
  const [html, setHtml] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastFetched, setLastFetched] = useState("");

  // useEffect(
  //   () => {
  //     if (pageTitle) {
  //       setLoading(true);
  //       const params = new URLSearchParams({
  //         redirect: 'true',
  //       })
  //       fetch(
  //         'https://en.wikipedia.org/api/rest_v1/page/html/'
  //         + pageTitle.replaceAll(" ", "_")
  //         + '?' + params
  //         // { headers: {  }, "credentials": "include" },
  //       )
  //         .then(res => {
  //         console.log(res)
  //         return res.text()
  //       })
  //         .then(data => {
  //           setHtml(data)
  //           setLoading(false);
  //         })
  //     }
  //     else {
  //       setHtml("");
  //       setLoading(false);
  //     }
  //   },
  //   [pageTitle]
  // )

  useEffect(  
    () => {
      setLastFetched(undefined);

      if (pageid != undefined && pageid != null) {
        //console.log(pageid)

        const params = new URLSearchParams({
          'action': 'parse',
          'format': 'json',
          'pageid': pageid,
          'prop': 'text',
          'formatversion': 2,
          'redirects': 1,
          'disabletoc': 1,
          'disableeditsection': 1,
          'disablelimitreport': 1,
          'preview': 1,
          'useskin': 'vector-2022',
          'origin': '*',
        })

        setLoading(true);
        fetch(config.WIKI_API_URL + '?' + params)
          .then(res => {
            return res.json()
          })
          .then(data => {
            //console.log(data)
            const title = `<h1 id="firstHeading" class="firstHeading mw-first-heading"><span class="mw-page-title-main">${data.parse.title}</span></h1>`
            
            setHtml(title + data.parse.text);
            setLoading(false);
            setLastFetched(pageid);
          })
          .catch(err => {
            console.error('Error:', err);
          })
      }
      else {
        setHtml("");
        setLoading(false);
      }
    },
    [pageid]
  );

  return [html, lastFetched != pageid, loading];
}

export default useWiki;
