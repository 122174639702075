import React, { useEffect, useState } from 'react';

const re = /[\\?&]([^&=]+)=([^&=]+)/g;

const useQueryString = () => {
  const [cachedParams, setCachedParams] = useState({});

  useEffect(
    () => {
      const matches = window.location.search.matchAll(re)
      const newParams = [...matches]?.reduce((acc, m) => ({ ...acc, [m[1]]: m[2] }), {});
      setCachedParams(newParams);
    },
    [window.location.search]
  )

  const setSearchParams = newParams => {
    const url = new URL(window.location);
    Object.entries(newParams)?.forEach(([k, v]) => {
      if (v === undefined) url.searchParams.delete(k)
      else url.searchParams.set(k, v)
    });
    window.history.pushState(null, '', url);
  }

  return [cachedParams, setSearchParams];
}

export default useQueryString;
