import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import useTime from '../hooks/Time';
import { formatTime } from '../util/util';
import Path from './Path';

const RunStatus = ({ show, startTime, path, settings }) => {
  const nodeRef = useRef();

  const msNow = useTime(show);

  const [millis, seconds, minutes, hours] = formatTime(msNow - startTime);
  const clicks = (path?.length ?? 1) - 1;

  const timerText = `${hours > 0 ? hours + ':' : ''}${minutes}:${seconds}:${millis}`;
  const clicksText = `${clicks} click${clicks === 1 ? '' : 's'}`;

  const primaryText = settings?.clicks ? clicksText : timerText;
  const secondaryText = settings?.clicks ? timerText : clicksText;

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={3000}>
      <div ref={nodeRef} className='run-status'>
        <label className='primary'>{primaryText}</label>
        <label className='secondary'>{secondaryText}</label>
        <Path path={path} />
      </div>
    </CSSTransition>
  );
}

export default RunStatus;
