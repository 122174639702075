import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import NiceContainer from './NiceContainer';

const PartyMembers = ({ members }) => {
  const nodeRef = useRef(null);
  const [membersDelayed, setMembersDelayed] = useState({})

  useEffect(() => {
    const toAdd = {};
    const toDel = { ...membersDelayed }

    Object.keys(membersDelayed)?.forEach(m => toAdd[m] = false);
    members?.forEach(m => {
      toAdd[m] = true;
      delete toDel[m];
    });
    setMembersDelayed(ms => { const applied = ({ ...ms, ...toAdd }); return applied });

    const del = () => setMembersDelayed(ms => {
        const newMembers = { ...ms }
        Object.keys(toDel).forEach(key => {
          delete newMembers[key];
        });
        // console.log(ms, "to", newMembers)
        return newMembers;
    });
    const removeTimeout = setTimeout(del, 3000);

    return () => clearTimeout(removeTimeout);
  }, [members]);

  const show = (members?.length ?? 0) > 0;

  return (
    <CSSTransition nodeRef={nodeRef} in={show} timeout={3000}>
      <div className='party-members' ref={nodeRef}>
        <NiceContainer label='Party members'>
          <div className='party-members-content'>
            {Object.entries(membersDelayed).map(([key, val], i) => <PartyMember2 key={key} username={key} show={val} leader={i === 0}/>)}
          </div>
        </NiceContainer>
      </div>
    </CSSTransition>
  )
}

const PartyMember = ({ username, leader }) => {
  const nodeRef = useRef(null);
  const [yes, setYes] = useState(false)

  useEffect(() => setYes(true), [])
  
  return (
    <CSSTransition nodeRef={nodeRef} in={yes} timeout={3000} classNames='party-member'>
      <label ref={nodeRef} className={`party-member ${leader ? 'leader' : ''}`}>{(leader ? '★ ' : '') + username}</label>
    </CSSTransition>
  );
}

const PartyMember2 = ({ username, leader, show }) => {
  const nodeRef = useRef(null);
  const [doShow, setDoShow] = useState(false)

  useEffect(() => setDoShow(show), [show])
  
  return (
    <CSSTransition nodeRef={nodeRef} in={doShow} timeout={3000}>
      <label disabled={!doShow} ref={nodeRef} className={`party-member ${leader ? 'leader' : ''}`}>{(leader ? '★ ' : '') + username}</label>
    </CSSTransition>
  );
}
export default PartyMembers;
