export const formatTime = ms => {
  const clamped = Math.max(0, ms)
  
  const millis = (clamped % 1000).toString().padStart(3, '0');
  const seconds = Math.floor((clamped / 1000) % 60).toString().padStart(2, '0');
  const minutes = Math.floor((clamped / 60000) % 60).toString().padStart(2, '0');
  const hours = Math.floor((clamped / 3600000) % 24).toString().padStart(1, '0');
  
  return [millis, seconds, minutes, hours];
}

export const isMetaPage = relativePath => {
  // Namespace listing at
  // https://en.wikipedia.org/wiki/Wikipedia:Namespace
  return (
    relativePath.startsWith('/wiki/User:') ||
    relativePath.startsWith('/wiki/Wikipedia:') ||
    relativePath.startsWith('/wiki/File:') ||
    relativePath.startsWith('/wiki/MediaWiki:') ||
    relativePath.startsWith('/wiki/Template:') ||
    relativePath.startsWith('/wiki/Help:') ||
    relativePath.startsWith('/wiki/Category:') ||
    relativePath.startsWith('/wiki/Portal:') ||
    relativePath.startsWith('/wiki/Draft:') ||
    relativePath.startsWith('/wiki/TimedText:') ||
    relativePath.startsWith('/wiki/Module:') ||
    relativePath.startsWith('/wiki/Gadget:') ||
    relativePath.startsWith('/wiki/Special:') ||
    relativePath.startsWith('/wiki/Media:')
  );
}

export const isBruhName = name => {
  return (
    name.toLowerCase().includes('iiro') ||
    name.toLowerCase().includes('iibae') ||
    name.toLowerCase().includes('luttinen') ||
    (name.toLowerCase().includes('atte') && name.toLowerCase().includes('mother')) ||
    (name.toLowerCase().includes('atte') && name.toLowerCase().includes('motter')) ||
    (name.toLowerCase().includes('atte') && name.toLowerCase().includes('mom'))
  );
}
