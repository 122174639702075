import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

const TextInput = ({ name, setLabel, cancelLabel, value, show, disabled, set, reset }) => {
  const [input, setInput] = useState("");

  const nodeRefSet = useRef();
  const nodeRefReset = useRef();

  const onSubmit = evt => {
    evt.preventDefault();
    set(input);
  };

  const onReset = () => {
    setInput("");
    reset();
  };

  const showInput = !value && show;
  const showReset = !!value && show;

  // Reset input if value is externally cleared
  useEffect(() => { if (!!value) setInput("") }, [value]);

  return (
    <>
      <CSSTransition in={showInput} nodeRef={nodeRefSet} timeout={3000} unmountOnExit classNames='input-line'>
        <form className='input-line' ref={nodeRefSet} onSubmit={onSubmit}>
          {showInput
            ? (
              <>
                <label htmlFor={name}>{setLabel}</label>
                <input type='text' disabled={disabled} autoFocus id={name} value={input} onChange={evt => setInput(evt.target.value)} />
              </>
            )
            : null}
        </form>
      </CSSTransition>
      <CSSTransition in={showReset} nodeRef={nodeRefReset} timeout={5000} unmountOnExit classNames='input-line'>
        <div className='input-line' ref={nodeRefReset}>
          <label> {cancelLabel}</label>
          <button onClick={onReset}>Reset</button>
        </div>
      </CSSTransition>
    </>
  );
}

export default TextInput;
