import React from 'react';

const NiceContainer = ({ label, children, className }) => {
  return (
    <div label={label} className='nice-container'>
      {children}
    </div>
  );
}

export default NiceContainer;
