import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import usePageDetails from '../hooks/PageDetails'
import { isMetaPage } from '../util/util';

const LinkMouseOver = ({ atag, mousePos, target, settings }) => {
  const re = /^\/wiki\/([^#\n]*)(?:#(.*))?$/;
  const path = decodeURIComponent(atag?.getAttribute('href'));
  const match = path?.match(re);
  const title = (match?.length > 1) ? match[1]?.replaceAll('_', ' ') : '';
  const section = (match?.length > 2) ? match[2]?.replaceAll('_', ' ') : '';

  const [pageDetails, detailsLoading] = usePageDetails(title);

  if (!atag || !mousePos) return null;

  const isTarget = title === target?.title || pageDetails?.id == target?.id;
  const isIllegalMeta = !settings.metaPages && isMetaPage(path);

  const rectMid = rect => [
    rect.x + (rect.width / 2),
    rect.y + (rect.height / 2),
  ];

  const rectMouseProximity = rect => {
    const [rx, ry] = rectMid(rect);
    return (rx - mousePos.x)**2 + (ry - mousePos.y)**2
  }

  const bbs = [...atag.getClientRects()];
  const bb = (bbs?.length > 1)
    ? bbs.reduce((a, b) => rectMouseProximity(a) < rectMouseProximity(b) ? a : b)
    : atag.getBoundingClientRect();

  const [x, y] = bb ? rectMid(bb) : [-1000, -1000];

  let wikiClassName = 'link-mouseover';
  if (isTarget) wikiClassName += ' target';
  if (isIllegalMeta) wikiClassName += ' illegal';

  return (
    <div className={wikiClassName} style={{ left: x + window.scrollX, top: y + window.scrollY }} >
      <label className='title'>{title + (section ? ` @ ${section}` : '')}</label>
      {pageDetails?.redirect && (
        <>
          <label className='redirect'>▼ Redirects to ▼</label>
          <label className='title'>{pageDetails.redirect.to}</label>
        </>
      )}
      <label className='description'>
        {pageDetails
          ? pageDetails.description ?? 'No description'
          : 'Loading description...'}
      </label>
      {isIllegalMeta && (
        <label className='illegal-note'>{'(Disallowed meta page)'}</label>
      )}

    </div>
  )
}

export default LinkMouseOver;
