import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import errorIcon from '../../res/error.svg'

const Error = ({ latestErr }) => {
  const nodeRef = useRef(null);
  const [visible, setVisible] = useState(false)

  useEffect(
    () => {
      if (latestErr?.msg) {
        setVisible(true);
        const timeout = setTimeout(() => setVisible(false), 2277);
        return () => clearTimeout(timeout);
      }
    },
    [latestErr?.id]
  )

  return (
    <CSSTransition nodeRef={nodeRef} in={visible} timeout={3000} classNames='error' unmountOnExit >
      <div className='error-container' ref={nodeRef} >
        <div className='error'>
          <img src={errorIcon} />
          <label className='error-label'>{latestErr?.msg}</label>
          <label className='error-close' onClick={() => setVisible(false)}>X</label>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Error;
