import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import errorIcon from '../../res/error.svg'

const Path = ({ path }) => {
  const reversePath = [...(path ?? [])].reverse();
  
  return (
    <div className='path-container' tabIndex={0}>
      <div className='path-container-inner'>
        <div className='path'>
          {reversePath?.map((page, idx) => <PathPage key={page.title} page={page} first={idx === path.length - 1} />)}
        </div>
      </div>
    </div>
  );
}

const PathPage = ({ page, first }) => {
  return (
    <div className='path-page'>
      {first || <label className='path-arrow'>{'⯈'}</label>}
      <label className={'path-pill' + (first ? ' first' : '')}>{page.title}</label>
    </div>
  );
}

export default Path;
