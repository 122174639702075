import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import useWiki from '../hooks/Wiki'
import usePageDetails from '../hooks/PageDetails'

// import "../../css/wiki2.css"
import "../../css/all4.css"
import LinkMouseOver from './LinkMouseOver';
import { isMetaPage } from '../util/util';

const Wikirun = ({ show, page, target, advance, settings }) => {
  const [nextTitle, setNextTitle] = useState("")
  const [hoveredLink, setHoveredLink] = useState(undefined);
  const [hoveredPos, setHoveredPos] = useState(undefined);
  const nodeRef = useRef();

  const [nextPage, detailsLoading] = usePageDetails(nextTitle);

  const [wikiContent, oldContent, loading] = useWiki(page?.id)

  const handleClick = evt => {
    evt.preventDefault();

    if (loading) return;

    const target = evt.target || evt.srcElement;
    if (target?.tagName === 'A') {
      const path = decodeURIComponent(target.getAttribute('href'));

      if (!settings?.metaPages && isMetaPage(path)) return;

      const re = /^\/wiki\/(.*)$/; // /^https?:\/\/en.wikipedia.org\/wiki\/(.*)$/;
      const match = path.match(re);

      if (match?.length > 1) {
        const pageTitle = match[1];
        setNextTitle(pageTitle);
      }
    }
  };

  const handleHover = evt => {
    const target = evt.target || evt.srcElement;
    if (target?.tagName === 'A') {
      setHoveredLink(target);
      setHoveredPos(({ x: evt.clientX, y: evt.clientY }));
    }
  };

  const handleUnHover = evt => {
    setHoveredLink(undefined);
    setHoveredPos(undefined);
  };

  // Advance when next page details have loaded
  useEffect(() => {
    if (nextPage) advance(({ id: nextPage.id, title: nextPage.title }));
    setNextTitle("");
  }, [nextPage]);

  // Scroll to top on page change
  useEffect(() => { if (!loading) window.scrollTo(0, 0) }, [loading, show]);
  // Scroll to top on visibility change
  useEffect(() => window.scrollTo(0, 0), [show]);

  let wikiClassName = 'wiki';
  if (!settings?.infoboxes) wikiClassName += ' no-infoboxes';
  if (!settings?.thumbnails) wikiClassName += ' no-thumbs';
  if (!settings?.seeAlso) wikiClassName += ' no-navboxes';
  if (!settings?.navboxes) wikiClassName += ' no-navboxes';

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={3000}>
      <div className='wiki-run' ref={nodeRef}>
        {/* <div className='wiki-run-content'> */}
          <div
            onClick={handleClick}
            onMouseOver={handleHover}
            onMouseOut={handleUnHover}
            className={`${wikiClassName} vector-body ve-init-mw-desktopArticleTarget-targetContainer${loading ? ' loading' : ''}`}
            dangerouslySetInnerHTML={{ __html: wikiContent }}
          />
        {/* </div> */}
        {/* <div className={`wiki ${loading ? 'loading' : ''}`} ref={nodeRef}>
          <iframe
            className='content'
            onClick={handleClick}
            srcDoc={wikiContent}
          />
        </div> */}
        <LinkMouseOver
          atag={hoveredLink}
          mousePos={hoveredPos}
          target={target}
          settings={settings}
        />
      </div>
    </CSSTransition>
  )
}

export default Wikirun;
