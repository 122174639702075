import React, { useEffect, useState }  from 'react';
import { config } from '../util/config';

const usePageDetails = title => {
  const [result, setResult] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [cache, setCache] = useState({});

  const search = title => {
    if (cache && title in cache) {
      setLoading(false);
      setResult(cache[title]);
      return
    }

    setResult(undefined);

    if (!!title) {
      setLoading(true);

      const params = new URLSearchParams({
        'action': 'parse',
        'format': 'json',
        'page': title.replaceAll('_', ' '),
        'prop': 'properties',
        'formatversion': 2,
        'redirects': 1,
        'origin': '*',
      })

      // fetch(config.WIKI_REST_URL + '/search/title?limit=1&q=' + title)
      fetch(config.WIKI_API_URL + '?' + params)
        .then(res => {
          return res.json()
        })
        .then(data => {
          const r = data.parse;
          const result = {
            id: r.pageid,
            title: r.title,
            description: r.properties?.['wikibase-shortdesc'],
            redirect: (r.redirects?.length > 0) ? r.redirects[0] : undefined,
          };
          setResult(result);
          setCache(c => ({ ...c, [title]: result}));
          setLoading(false);
        })
        .catch(err => {
          console.error('Error fetching wiki page details', err);
        })
    }
    else {
      setLoading(false);
    }
  };

  useEffect(() => search(title), [title]);

  useEffect(() => { return () => console.log("kys")}, [])

  return [result, loading];
}

export default usePageDetails;
