import React, { useEffect, useState, useRef } from 'react';
import Error from './components/Error';

import PageSelect from './components/PageSelect';
import PartyMembers from './components/PartyMembers'
import Opponents from './components/Opponents';
import RunStatus from './components/RunStatus';
import RunSettings, { defaultSettings } from './components/RunSettings';
import Start from './components/Start';
import TextInput from './components/TextInput';
import Wikirun from './components/Wikirun'
import useParty from './hooks/Party.js';
import useQueryString from './hooks/queryString';
import { config } from './util/config';
import PostGame from './components/PostGame';
import { isBruhName } from './util/util';
import Button from './components/Button';

const Application = () => {
  const [username, setUsername] = useState("");
  const [partyName, setPartyName] = useState("");
  const [latestErr, setLatestErr] = useState({ id: 0, msg: "" });

  const [qString, setQString] = useQueryString();

  const party = useParty(
    username,
    partyName,
    () => { setUsername(""); setPartyName(qString.party ?? ''); }
  );

  const round = party?.Round;
  const roundEnded = round?.Ended ?? false;
  const run = round?.Runs?.find(r => r.Username === username);
  const runFinished = (run?.EndTime ?? 0) != 0;
  const runStart = run?.StartTime ?? 0;
  const path = run?.Path;
  const latestPage = (path?.length > 0) ? path[path.length - 1] : undefined;
  const settings = party?.Settings;
  const target = party?.Target;
  const source = party?.Source;

  const bruh = isBruhName(username);

  const isPartyLeader = party?.Users?.length > 0
    ? party.Users[0] === username
    : false;
  
  const error = errMsg => {
    console.warn(errMsg);
    setLatestErr(prev => ({ id: prev.id + 1, msg: errMsg }));
  }

  console.log("party", party)
  console.log('round',round);
  console.log('roundEnded',roundEnded);
  console.log('run',run);
  console.log('runFinished',runFinished);
  console.log('runStart',runStart);
  console.log('path', path);
  console.log('isPartyLeader',isPartyLeader);

  const configure = partyConfig => {
    if (!isPartyLeader) return;
    console.log("partyconifg", partyConfig);
    fetch(config.API_URL + '/party/config?' + new URLSearchParams({
      user: username,
      source: JSON.stringify(partyConfig?.source === undefined ? source : partyConfig?.source),
      target: JSON.stringify(partyConfig?.target === undefined ? target : partyConfig?.target),
      settings: JSON.stringify(partyConfig?.settings === undefined ? settings : partyConfig?.settings),
    }))
      .then(res => { if (!res.ok) res.text().then(body => error(body)) })
  }

  const start = () => {
    fetch(config.API_URL + '/round?' + new URLSearchParams({
      user: username,
    }))
      .then(res => { if (!res.ok) res.text().then(body => error(body)) })
  }

  const advance = page => {
    fetch(config.API_URL + '/advance?' + new URLSearchParams({
      user: username,
      page: JSON.stringify(page),
    }))
      .then(res => { if (!res.ok) res.text().then(body => error(body)) })
  }

  const abandon = () => {
    fetch(config.API_URL + '/abandon?' + new URLSearchParams({
      user: username,
    }))
      .then(res => { if (!res.ok) res.text().then(body => error(body)) })
  }

  const forceFinishRound = () => {
    fetch(config.API_URL + '/forcefinish?' + new URLSearchParams({
      user: username,
    }))
      .then(res => { if (!res.ok) res.text().then(body => error(body)) })
  }

  const endRound = () => {
    fetch(config.API_URL + '/end?' + new URLSearchParams({
      user: username,
    }))
      .then(res => { if (!res.ok) res.text().then(body => error(body)) })
  }

  // Prefill party name based on URL param
  useEffect(() => { if (qString?.party) setPartyName(qString.party) }, [qString]);

  useEffect(() => { if (!!party) setQString({ party: party.Name }) }, [party]);

  // Configure settings on login to new party
  useEffect(() => {
    const s = JSON.parse(localStorage.getItem('settings')) ?? defaultSettings;
    if (isPartyLeader && !settings) configure({ settings: s })
  }, [party]);

  // Store applied settings to local storage if party leader
  useEffect(() => {
    if (settings) localStorage.setItem('settings', JSON.stringify(settings))
  }, [settings]);

  return (
    <>
      <div className={'container' + (bruh ? ' bruh' : '')}>
        <div className='side'>
        {/* <div className='sidee'> */}
          <div className='side-content'>
            <TextInput
              name='login'
              setLabel={<>My name is... </>}
              cancelLabel={<>Welcome <b>{username}</b></>}
              value={username}
              show={!run || runFinished}
              set={setUsername}
              reset={() => { setUsername(""); }}
            />
            <TextInput
              name='join'
              setLabel={<>I'll join a party called... </>}
              cancelLabel={<>In party <b>{party?.Name}</b></>}
              value={party?.Name ?? partyName}
              show={(!run || runFinished) && !!username}
              set={name => { setPartyName(name);}}
              reset={() => { setPartyName(""); setQString({ party: undefined })}}
            />
            <PartyMembers
              self={username}
              members={party?.Users}
            />
            <PageSelect
              name='source'
              label='from...'
              show={!!party}
              disabled={(!!round && !roundEnded) || !isPartyLeader}
              selection={source}
              setSelection={selection => configure({ source: selection })}
              focus={!!party}
              overrideValue={roundEnded && isPartyLeader ? undefined : party?.Source?.title}
            />
            <PageSelect
              name='target'
              label='to...'
              show={!!party}
              disabled={(!!round && !roundEnded) || !isPartyLeader}
              selection={target}
              setSelection={selection => { console.log("SETSELECTON", selection); configure({ target: selection }) }}
              focus={!!party && !!source?.title}
              overrideValue={roundEnded && isPartyLeader ? undefined : party?.Target?.title}
            />
            <RunStatus
              show={!!run && !runFinished}
              startTime={runStart}
              path={path}
              settings={settings}
            />
            <Opponents
              show={!!run && !runFinished}
              users={party?.Users}
              round={round}
              settings={settings}
            />
            <Button
              show={!!run && !runFinished}
              label='Abandon run'
              onClick={abandon}
            />
            </div>
          {/* </div> */}
          {/* <button onClick={() => advance(run?.Target)}>Finish run (cheat)</button> */}
        </div>
        <RunSettings
          show={!!party && (!round || roundEnded)}
          settings={settings}
          setSettings={s => configure({ settings: s })}
          disallowEdit={!isPartyLeader}
          ready={isPartyLeader && !!source?.title && !!target?.title}
          start={start}
        />
        <PostGame
          show={!!round && !roundEnded && (!run || runFinished)}
          users={party?.Users}
          isPartyLeader={isPartyLeader}
          round={round}
          settings={settings}
          forceFinishRound={forceFinishRound}
          endRound={endRound}
        />
        {/* <Start
          show={!round || roundEnded}
          enabled={isPartyLeader && !!source && !!target}
          start={start}
        /> */}
        <Wikirun
          show={!!run && !runFinished}
          page={latestPage}
          advance={advance}
          target={run?.Target}
          settings={settings}
        />
      </div>
      <Error latestErr={latestErr} />
    </>
  )
}

export default Application;
