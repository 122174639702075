import React, { useEffect, useState, useRef } from 'react';

const useTime = running => {
  const [now, setNow] = useState(0);
  const [intervalHandle, setInvervalHandle] = useState(null);

  const updateTime = () => setNow(Date.now());
  const stop = () => {
    setInvervalHandle(handle => {
      if (handle) clearInterval(handle);
      return null;
    });
  }

  useEffect(() => {
    if (running) setInvervalHandle(prev => prev || setInterval(updateTime, 11));
    else stop();

    return stop;
  }, [running]);

  return now;
}

export default useTime;
