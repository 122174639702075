import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import Opponents from './Opponents';

const PostGame = ({ show, users, isPartyLeader, round, settings, forceFinishRound, endRound }) => {
  const nodeRef = useRef(null);

  const roundFinished = round?.Runs?.every(r => r.EndTime !== 0) ?? false;

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={3000}>
      <div className='post-game' ref={nodeRef} >
        <div className='post-game-align'>
          <div className='post-game-content'>
            <Opponents
              show={show}
              users={users}
              round={round}
              settings={settings}
              postGame
            />
            {!roundFinished && <label>Run ongoing...</label>}
            {(!roundFinished) && <button disabled={!isPartyLeader} onClick={forceFinishRound}>Force finish round</button>}
            {(roundFinished) && <button disabled={!isPartyLeader} onClick={endRound}>Close summary</button>}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default PostGame;
