import React, { useEffect, useReducer, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import useTime from '../hooks/Time';
import { formatTime } from '../util/util';
import MiniIcon from './MiniIcon';
import Path from './Path';

import disconnectedIcon from '../../res/disconnected.svg';
import userIcon from '../../res/user.svg';
import wikiIcon from '../../res/wikipedia-flat.svg';

const Opponents = ({ show, users, round, settings, postGame }) => {
  const nodeRef = useRef(null);

  const timeSortedRuns = round?.Runs?.sort(r => -r.EndTime);
  const timedRankedRuns = round?.Runs?.map(r => ({ ...r, placement: timeSortedRuns.findIndex(rr => rr.Username === r.Username) + 1 }))

  let showTimers = postGame;
  let showClicks = !!settings?.clicks && !!settings?.showOthersClicks;
  let showPages = !!settings?.showOthersCurrent;
  let showStatuses = !!settings?.showOthersStatus;
  let showPaths = !!settings?.showOthersPath;

  const roundFinished = round?.Runs?.every(r => r.EndTime !== 0) ?? false;
  const hideAll = !!settings?.hideInfoUntilEnd && !roundFinished;
  showTimers = (showTimers || postGame) && !hideAll;
  showClicks = (showClicks || postGame) && !hideAll;
  showPages = (showPages || postGame) && !hideAll;
  showStatuses = (showStatuses || postGame) && !hideAll;
  showPaths = (showPaths || postGame) && !hideAll;

  return (
      <div className='opponents' >
        <CSSTransition nodeRef={nodeRef} in={show} timeout={3000}>
          <label className='title'ref={nodeRef}>Opponents</label>
        </CSSTransition>
        <div className='opponents-content'>
          {timedRankedRuns?.map(run =>
            <Opponent
              show={show}
              key={run.Username}
              run={run}
              loggedIn={users?.some(u => u === run.Username)}
              showTimer={showTimers}
              showClicks={showClicks}
              showPage={showPages}
              showStatus={showStatuses}
              showPath={showPaths}
              postGame={postGame}
            />
          )}
        </div>
      </div>
  );
}

const Opponent = ({ show, run, loggedIn, showTimer, showClicks, showPage, showStatus, showPath, postGame }) => {
  const nodeRef = useRef(null);
  const [doShow, setDoShow] = useState(false)

  const clicks = (run?.Path?.length ?? 1) - 1;
  const clicksText = `🖱️ ${clicks} click${clicks === 1 ? '' : 's'}`;
  const ordinal = n => ['st', 'nd', 'rd'][((n + 90) % 100 - 10) % 10 - 1] || 'th';

  let status, statusClass;
  switch (run.EndTime) {
    case -1:
      status = 'DNF';
      statusClass = 'dnf';
      break;
    case 0:
      status = 'Running';
      statusClass = 'running';
      break;
    default:
      status = run.placement + ordinal(run.placement);
      statusClass = 'finished' + (run.placement === 1 ? ' first' : '');
  }
  statusClass += (loggedIn ? ' loggedIn' : ' loggedOut');

  useEffect(() => setDoShow(show), [show])

  return (
    <CSSTransition nodeRef={nodeRef} in={doShow} timeout={3000}>
      <div className={'opponent' + (showPath ? ' with-path' : '')} ref={nodeRef}>
        <div className='opponent-info'>
          <label className='username'>
            <MiniIcon svg={loggedIn ? userIcon : disconnectedIcon} />
            {run.Username}
          </label>
          {showTimer && <label className='timer'><RunTimer run={run}/></label>}
          {showClicks && <label className='clicks'>{clicksText}</label>}
          {showPage &&
            <label className='page'>
              <MiniIcon svg={wikiIcon} />
              {`${run.Path[run.Path.length - 1]?.title}`}
            </label>
          }
          {showStatus && <label className={'opponent-status ' + statusClass}>{status}</label>}
        </div>
        {showPath &&
          <div className='opponent-path-container'>
            {postGame && <label>┗</label>}
            <Path path={run?.Path} />
            {!postGame && <label>┛</label>}
          </div>
        }
      </div>
    </CSSTransition>
  )
}

const RunTimer = ({ run }) => {
  const msNow = useTime(run.EndTime === 0);

  const time = run.EndTime === 1
    ? 0 // DNF
    : run.EndTime === 0
    ? msNow - run.StartTime // Running
    : run.EndTime - run.StartTime; // Finished
  const [millis, seconds, minutes, hours] = formatTime(time);

  return (
    <>
      {`🕑 ${hours > 0 ? hours + ':' : ''}${minutes}:${seconds}:${millis}`}
    </>
  )
}

export default Opponents;
