import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import usePageSearch from '../hooks/PageSearch'

import crossLogo from '../../res/cross.svg';

const PageSelect = ({ name, label, show, selection, setSelection, disabled, focus, overrideValue }) => {
  const [input, setInput] = useState("");
  const [suggestions, loading] = usePageSearch(input)
  const [focused, setFocused] = useState(false);

  const nodeRef = useRef();
  const inputRef = useRef();

  const haveSelection = selection?.title ?? false;

  const reset = () => {
    setSelection(null);
    console.log(inputRef)
    setTimeout(() => inputRef?.current?.focus(), 100);
  };

  useEffect(() => {
    setInput(selection?.title ?? '');
  }, [selection])

  useEffect(
    () => { if (focus) inputRef?.current?.focus() },
    [focus, inputRef]
  );

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={3000} unmountOnExit classNames='input-line'>
      <form
        className={'pageselect input-line ' + name}
        ref={nodeRef}
        onSubmit={evt => evt.preventDefault()}
        onFocus={() => setTimeout(setFocused(true))}
        onBlur={() => setTimeout(setFocused(false))}
      >
        <label htmlFor={name}>{label}</label>
        <input
          autoComplete='off'
          type='text'
          ref={inputRef}
          id={name}
          placeholder='Type to see suggestions..'
          // value={overrideValue ?? input}
          value={input}
          onChange={evt => setInput(evt.target.value)}
          disabled={disabled || haveSelection}
        />
        <div>
          <AutoComplete
            show={!disabled && !haveSelection && !!input && (suggestions?.length > 0) && !!focused}
            suggestions={suggestions}
            setSelection={setSelection}
          />
        </div>
        {!disabled && haveSelection ? <img className='cross' src={crossLogo} onClick={reset}/> : null}
      </form>
    </CSSTransition>
  )
}

const AutoComplete = ({ show, suggestions, setSelection }) => {
  const nodeRef = useRef();

  return (
    <CSSTransition in={show}  nodeRef={nodeRef} timeout={3000} classNames='autocomplete'>
      <div className='autocomplete' ref={nodeRef}>
        {suggestions.map((s, i) =>
          <label className='autocomplete-suggestion' key={i} onClick={evt => setSelection(s)}>
            {s.title}
          </label>
        )}
      </div>
    </CSSTransition>
  )
}

export default PageSelect;
