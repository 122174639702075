import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

const Button = ({ show, label, onClick }) => {
  const nodeRef = useRef();

  return (
    <CSSTransition in={show} nodeRef={nodeRef} timeout={3000}>
      <button ref={nodeRef} className='button' onClick={onClick}>{label}</button>
    </CSSTransition>
  )
}

export default Button;
