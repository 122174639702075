import React, { useEffect, useState }  from 'react';
import { config } from '../util/config';

const usePageSearch = query => {
  const [results, setResults] = useState({timestamp : 0, results: []});
  const [loading, setLoading] = useState(false);

  const DISAMBIGUATION_DESCRIPTION = 'Topics referred to by the same term'

  // useEffect(
  //   () => {
  //     if (query) {
  //       console.log(query)

  //       let formData = new FormData();
  //       formData.append('action', 'query');
  //       formData.append('list', 'search');
  //       formData.append('format', 'json');
  //       formData.append('formatversion', 2);
  //       formData.append('redirects', 1);
  //       formData.append('srsearch', query);
  //       formData.append('origin', '*');

  //       const timestamp = Date.now();

  //       setLoading(true);
  //       fetch(apiURL,
  //       {
  //         body: formData,
  //         method: "post",
  //         mode: 'cors',
  //       })
  //         .then(res => {
  //           return res.json()
  //         })
  //         .then(data => {
  //           console.log(data)

  //           setResults(r => {
  //             if (r.timestamp > timestamp) return r
  //             else return { timestamp: timestamp, results: data.query.search }
  //           });
  //           setLoading(false);
  //         })
  //         .catch(err => {
  //           console.error('Error:', err);
  //         })
  //     }
  //     else {
  //       setResults({timestamp : 0, results: []});
  //       setLoading(false);
  //     }
  //   },
  //   [query]
  // );

  const searchPages = () => {
    //console.log(query)
    const timestamp = Date.now();
    setLoading(true);
    
    fetch( config.WIKI_REST_URL + '/search/title?limit=10&q=' + query)
      .then(res => {
        return res.json()
      })
      .then(data => {
        setResults(r => {
          if (r.timestamp > timestamp) return r
          else return {
            timestamp: timestamp,
            results: data.pages
              .filter(p => p.description !== DISAMBIGUATION_DESCRIPTION)
              .map(r => ({ id: r.id, title: r.title, description: r.description }))
          }
        });
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching wiki pages', err);
      })
  };

  useEffect(
    () => {
      if (query) {
        const timeout = setTimeout(() => searchPages(query), 250);
        return () => clearTimeout(timeout);
      }
    },
    [query]
  );

  return [results.results, loading];
}

export default usePageSearch;
